import { forwardRef } from 'react'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

export const ReactMaterialTable = ({
    columns,
    data,
    searchbar,
    toolbar,
    fontSize,
    pagging,
    selectedData,
    selectedRow,
    exportbtn,
    id,
    onDeleteRow,
    onEditRow,
    DeleteBtn,
    EditBtn,
    title,
}) => {
    return (
        <MaterialTable
            className="Poppins-Regular"
            columns={columns}
            icons={tableIcons}
            data={data}
            title={title}
            style={{
                // maxWidth: '90vw',
                // background: 'transparent',
                // minHeight: '800px',
                margin: '0 auto',
                fontSize: fontSize,
                // border: 'none',
                // boxShadow: 'none',
                overflow: 'visible',
            }}
            // onRowClick={(evt, selectedRow) =>
            //   // setSelectedRow(selectedRow.tableData.id),
            //   selectedData(selectedRow.tableData.id)
            // }

            options={{
                sorting: false,
                search: searchbar,
                toolbar: toolbar,
                exportButton: true,
                paging: false,
                selection: false,
                draggable: false,
                loadingType: 'overlay',

                pageSize: 10, // make initial page size
                emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
                // pageSizeOptions: [5, 10, 15 , 25, 50], // rows selection options
                // tableLayout: 'fixed',
                cellStyle: {
                    whiteSpace: 'nowrap',
                    // textAlign: 'left',
                    // border: 'none',
                },
                // rowStyle: (rowData) => ({
                //   backgroundColor:
                //     selectedRow === rowData.tableData.id ? '#EEE' : '#FFF',
                // }),
                headerStyle: {
                    whiteSpace: 'nowrap',
                    color: 'white',
                    background: '#16996F',
                    border: 'none',
                    fontSize: fontSize,
                    fontWeight: 'bold',
                    // textAlign: 'left',
                    //   width: 20,
                    // maxWidth: 20,
                },
                actionsColumnIndex: -1,
            }}
            actions={
                EditBtn && DeleteBtn
                    ? [
                          {
                              icon: Edit,
                              tooltip: 'Save User',
                              onClick: (event, rowData) => onEditRow(rowData),
                              position: 'row',
                          },
                          {
                              icon: DeleteOutline,
                              tooltip: 'Delete User',
                              onClick: (event, rowData) => onDeleteRow(rowData),
                              position: 'row',
                          },
                      ]
                    : EditBtn
                    ? [
                          {
                              icon: Edit,
                              tooltip: 'Save User',
                              onClick: (event, rowData) => onEditRow(rowData),
                              position: 'row',
                          },
                      ]
                    : DeleteBtn
                    ? [
                          {
                              icon: DeleteOutline,
                              tooltip: 'Delete User',
                              onClick: (event, rowData) => onDeleteRow(rowData),
                              position: 'row',
                          },
                      ]
                    : null
            }
        />
    )
}
