export const END_POINTS = {
        CNIC: {
            GET: 'get',
            ADD: 'add',
            GET_ALL: 'applicants',
            DELETE: 'delete',
            UPDATE: 'update'
        },
        AUTH: {
            LOGIN: 'login',
            REGISTER: 'register'
        }
}