import { ON_LOGOUT_USER, ON_LOGIN_USER } from '../Types'

export const onLogoutUser = () => ({
    type: ON_LOGOUT_USER,
})

export const onLogInUser = (payload) => ({
    type: ON_LOGIN_USER,
    payload,
})
