// import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import React, { useState } from 'react'
import { useDispatch} from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { IMG } from '../assets/path'
import { onLogoutUser } from '../redux/actions/user'

export const PagesHandler = ({ children }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const { data } = useSelector((state) => state.UserReducer)
    // const [userState, setuserState] = useState(false)

    const onLogout = async () => {
        await dispatch(onLogoutUser())
        navigate('/')
    }
    return (
        <div className="flex flex-col md:flex-row bg-white-normal max-h-screen">
            <div className='flex md:hidden bg-green-normal text-xs sm:text-sm'>
                <NavLink
                    className={(navData) =>
                        navData.isActive
                            ? 'bg-white-normal h-10 xl:h-12 flex items-center px-2 sm:px-5 text-green-normal'
                            : 'bg-transparent border border-white-normal text-white-normal hover:bg-white-normal hover:text-green-normal h-10 xl:h-12 flex items-center px-2 sm:px-5'
                    }
                    to={'/dashboard'}>
                    Dashboard
                </NavLink>
                <NavLink
                    className={(navData) =>
                        navData.isActive
                            ? 'bg-white-normal h-10 xl:h-12 flex items-center px-2 sm:px-5 text-green-normal'
                            : 'bg-transparent border border-white-normal text-white-normal hover:bg-white-normal hover:text-green-normal h-10 xl:h-12 flex items-center px-2 sm:px-5'
                    }
                    to={'/add-licence'}>
                    Add License
                </NavLink>

                <div
                    onClick={onLogout}
                    // activeClassName={classes.DrawerActiveClass}
                    className={`h-10 xl:h-12 flex items-center px-2 sm:px-5 cursor-pointer bg-transparent border border-white-normal text-white-normal hover:bg-white-normal hover:text-green-normal`}>
                    Logout
                </div>
            </div>
            <div className="hidden md:block md:w-3/12 relative  bg-white pb-5">
                <div className="flex flex-col max-h-min md:px-5 xl:pl-8">
                    <div className="flex flex-col  bg-green-normal min-h-screen">
                        <div className="my-8 flex items-center justify-center">
                            <img src={IMG.LOGO} alt="" className="w-28 h-28" />
                        </div>

                        <NavLink
                            className={(navData) =>
                                navData.isActive
                                    ? 'bg-white-normal h-10 xl:h-12 flex items-center px-5 text-green-normal'
                                    : 'bg-transparent border border-white-normal text-white-normal hover:bg-white-normal hover:text-green-normal h-10 xl:h-12 flex items-center px-5'
                            }
                            to={'/dashboard'}>
                            Dashboard
                        </NavLink>
                        <NavLink
                            className={(navData) =>
                                navData.isActive
                                    ? 'bg-white-normal h-10 xl:h-12 flex items-center px-5 text-green-normal'
                                    : 'bg-transparent border border-white-normal text-white-normal hover:bg-white-normal hover:text-green-normal h-10 xl:h-12 flex items-center px-5'
                            }
                            to={'/add-licence'}>
                            Add License
                        </NavLink>

                        <div
                            onClick={onLogout}
                            // activeClassName={classes.DrawerActiveClass}
                            className={`h-10 xl:h-12 flex items-center px-5 cursor-pointer bg-transparent border border-white-normal text-white-normal hover:bg-white-normal hover:text-green-normal`}>
                            Logout
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-9/12 ">{children}</div>
        </div>
    )
}
