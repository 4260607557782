import './App.css'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import { Main } from './container/main'
import { AdminRoute } from './routes/adminRoutes'
import { AdminLogin } from './container/dashboard/login'
import { AddLicence } from './container/dashboard/addLicense'
import { Dashboard } from './container/dashboard/dashboard'

function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/account/login648055" element={<AdminLogin />} />
                <Route
                    path="/dashboard"
                    element={<AdminRoute component={Dashboard} />}
                />
                <Route
                    path="/add-licence"
                    element={<AdminRoute component={AddLicence} />}
                />
            </Routes>
        </HashRouter>
    )
}

export default App
