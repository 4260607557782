import {createStore, compose} from 'redux'
// import {composeWithDevTools} from 'redux-devtools-extension'
import { rootReducers } from "./rootstore";
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage: storage,
}

const pReducer = persistReducer(persistConfig, rootReducers)

export const store = createStore(
    pReducer,
    // compose(composeWithDevTools())
)

export const persistor = persistStore(store)