import {
    query,
    getDocs,
    deleteDoc,
    doc,
    where,
    onSnapshot,
    updateDoc,
} from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
// import { ModalComponent } from '../../components/Modal'
import { ReactMaterialTable } from '../../components/table/table'
import { Firestorage, licenseRef } from '../../firebase'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import InputMask from 'react-input-mask'
import { ModalComponent } from '../../components/Modal'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { API_HANDLER } from '../../utils/function'
import { END_POINTS } from '../../utils/endpoints'

export const Dashboard = () => {
    const [formData, setformData] = useState([])
    const [Deletemodal, setDeletemodal] = useState(false)
    const [Editmodal, setEditmodal] = useState(false)
    const [deleteData, setdeleteData] = useState()
    const [editData, seteditData] = useState()
    const [loading, setloading] = useState(true)
    const [imageUpload, setImageUpload] = useState()
    const [progress, setprogress] = useState(0)
    const UploadImage = (e) => {
        e.preventDefault()
        var file = e.target.files[0]
        setImageUpload({
            url: URL.createObjectURL(file),
            file: file,
        })
    }

    useEffect(() => {
        onGetFormData()
    }, [])

    const onGetFormData = async () => {
        setloading(true)
        const data = await API_HANDLER('GET', END_POINTS.CNIC.GET_ALL , {
        })
        // console.log(data)
        setformData(data?.data)
        setloading(false)

        // const q = query(licenseRef)
        // setloading(true)
        // // const querySnapshot = await getDocs(q)
        // onSnapshot(q, (querySnapshot) => {
        //     let arr = []
        //     querySnapshot.forEach((doc) => {
        //         // doc.data() is never undefined for query doc snapshots
        //         arr.push(doc.data())
        //     })
        //     setformData(arr)
        //     setloading(false)
        // })
    }
    var columns = [
        {
            field: 'applicant_name',
            title: 'Applicant Name',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center justify-center">
                    <div className="font-medium text-center w-full">{rowData?.applicant_name}</div>
                </div>
            ),
        },
        {
            field: 'avatar',
            title: 'Applicant Avatar',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center justify-center">
                    <img
                        src={
                            rowData.avatar ||
                            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCquktKedv--avxFuVVanKzjajxj4DZaA689FqwBU&s'
                        }
                        className="w-10 h-10 object-center"
                    />
                </div>
            ),
        },
        {
            field: 'appliant_cnic',
            title: 'Applicant Cnic',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center">
                    <div className="font-medium text-center w-full">{rowData?.applicant_cnic}</div>
                </div>
            ),
        },
        {
            field: 'father_name',
            title: 'Father Name',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center">
                    <div className="font-medium text-center w-full">{rowData?.father_name}</div>
                </div>
            ),
        },
        {
            field: 'caliber',
            title: 'caliber',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center">
                    <div className="font-medium text-center w-full">{rowData?.caliber}</div>
                </div>
            ),
        },
        {
            field: 'cartridges',
            title: 'cartridges',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center">
                    <div className="font-medium text-center w-full">{rowData?.cartridges}</div>
                </div>
            ),
        },
        {
            field: 'licence_no',
            title: 'Licence No',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center">
                    <div className="font-medium text-center w-full">{rowData?.licence_no}</div>
                </div>
            ),
        },
        {
            field: 'weapon_no',
            title: 'Weapon No',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center">
                    <div className="font-medium text-center w-full">{rowData?.weapon_no}</div>
                </div>
            ),
        },
        {
            field: 'weapon_type',
            title: 'Weapon Type',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center">
                    <div className="font-medium text-center w-full">{rowData?.weapon_type}</div>
                </div>
            ),
        },
        {
            field: 'issue_date',
            title: 'Issue Date',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center">
                    <div className="font-medium text-center w-full">{rowData?.issue_date}</div>
                </div>
            ),
        },
        {
            field: 'expiry_date',
            title: 'Expiry Date',
            render: (rowData) => (
                <div className="cursor-pointer mx-auto flex items-center">
                    <div className="font-medium text-center w-full">{rowData?.expiry_date}</div>
                </div>
            ),
        },
    ]

    const onDeleteRow = (rowData) => {
        setdeleteData(rowData)

        setDeletemodal(!Deletemodal)
    }

    const onEditRow = async (rowData) => {
        console.log(rowData)
        seteditData(rowData)
        setEditmodal(!Editmodal)
    }

    const onConfirmDeleteRow = async () => {
        // await deleteDoc(doc(licenseRef, deleteData?.id))
        const data = await API_HANDLER('DELETE', END_POINTS.CNIC.DELETE , {
            _id: deleteData?._id
        })
        const List = formData.filter((item) => item?._id !== deleteData?._id)
        setformData(List)
        setDeletemodal(!Deletemodal)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            applicant_name: editData?.applicant_name,
            applicant_cnic: editData?.applicant_cnic,
            father_name: editData?.father_name,
            licence_no: editData?.licence_no,
            weapon_type: editData?.weapon_type,
            caliber: editData?.caliber,
            weapon_no: editData?.weapon_no,
            cartridges: editData?.cartridges,
            status: editData?.status,
            issue_date: editData?.issue_date,
            expiry_date: editData?.expiry_date,
        },
        validationSchema: Yup.object({
            applicant_name: Yup.string().required(
                'Please complete this required field.'
            ),
            applicant_cnic: Yup.string().required(
                'Please complete this required field.'
            ),
            licence_no: Yup.string().required(
                'Please complete this required field.'
            ),
            weapon_type: Yup.string().required(
                'Please complete this required field.'
            ),
            caliber: Yup.string().required(
                'Please complete this required field.'
            ),
            weapon_no: Yup.string().required(
                'Please complete this required field.'
            ),
            cartridges: Yup.string().required(
                'Please complete this required field.'
            ),
            status: Yup.string().required(
                'Please complete this required field.'
            ),
            issue_date: Yup.string().required(
                'Please complete this required field.'
            ),
            expiry_date: Yup.string().required(
                'Please complete this required field.'
            ),
        }),
        onSubmit: async (values) => {
            const timestamp = Date.now()
            if (imageUpload !== undefined) {
                var storageref = ref(Firestorage, `pkv/avatars/${timestamp}`)
                var task = uploadBytesResumable(storageref, imageUpload?.file)
                task.on(
                    'state_changed',
                    (snapshot) => {
                        const ping = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                                100
                        )
                        setprogress(ping)
                        //progresss
                    },
                    (error) => {
                        //incomplete
                    },
                    async () => {

                        getDownloadURL(task.snapshot.ref).then(async (url) => {
                            const data = await API_HANDLER('PATCH', END_POINTS.CNIC.UPDATE , {
                                _id: editData?._id,
                                ...values,
                                avatar: url,
                            })
                            const clone = [...formData]
                            clone[editData?.tableData?.id] ={ ...values , avatar: url}                            
                            setformData(clone)
                            // await updateDoc(doc(licenseRef, editData.id), {
                            //     ...values,
                            //     issue_date: values.issue_date,
                            //     expiry_date: values.expiry_date,
                            //     avatar: url,
                            // })
                            // setloading(false)
                        })
                    }
                )
            } else {
                const data = await API_HANDLER('PATCH', END_POINTS.CNIC.UPDATE , {
                    _id: editData?._id,
                    ...values,
                })
                const clone = [...formData]
                clone[editData?.tableData?.id] = values
                setformData(clone)
                // return
                // await updateDoc(doc(licenseRef, editData.id), {
                //     ...values,
                //     issue_date: values.issue_date,
                //     expiry_date: values.expiry_date,
                // })
            }
            setEditmodal(!Editmodal)
        },
    })

    const DeleteModalContent = (
        <div className="p-5 bg-white-normal flex flex-col items-center justify-center outline-none w-8/12 sm:w-1/2 lg:w-min space-y-8">
            <div className="text-sm lg:text-base font-semibold">
                Do you really want to delete selected row?
            </div>

            <div className="flex space-x-3">
                <button
                    onClick={() => setDeletemodal(!Deletemodal)}
                    className="bg-green-normal w-28  h-7 md:h-8 rounded-sm text-white-normal">
                    CANCEL
                </button>
                <button
                    // type="submit"
                    onClick={onConfirmDeleteRow}
                    className="bg-green-normal w-28  h-7 md:h-8 rounded-sm text-white-normal">
                    OK
                </button>
            </div>
        </div>
    )

    const EditModalContent = (
        <div className="p-5 bg-white-normal flex flex-col items-center justify-center outline-none w-full lg:w-min space-y-8">
            <div className="text-sm lg:text-base font-semibold">Edit Data</div>
            <form
                onSubmit={formik.handleSubmit}
                className="w-full md:w-full text-xs space-y-2  flex flex-col items-center justify-center">
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">Applicant Image :</div>
                    <div className="flex w-full justify-center items-center ">
                        <label
                            htmlFor="imageUpload"
                            className="rounded-md cursor-pointer w-10 h-10">
                            <img
                                src={imageUpload !== undefined ? imageUpload.url :
                                    editData?.avatar ||
                                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCquktKedv--avxFuVVanKzjajxj4DZaA689FqwBU&s'
                                }
                                alt={
                                    imageUpload?.url !== ''
                                        ? 'Image Uploaded'
                                        : 'Upload Image'
                                }
                                className="self-center w-10 h-10"
                            />
                        </label>
                        <input
                            type="file"
                            className="hidden"
                            name="photo"
                            onChange={(e) => {
                                UploadImage(e)
                            }}
                            accept="image/*"
                            id="imageUpload"
                            alt=""
                        />
                    </div>
                </div>

                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">Applicant Name :</div>
                    <div>
                        {' '}
                        <input
                            style={{ border: '1px solid #CED4DA' }}
                            name="applicant_name"
                            className="h-8   rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.applicant_name}
                        />
                    </div>
                </div>
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40  font-semibold">Applicant Cnic :</div>
                    <div>
                        <InputMask
                            style={{ border: '1px solid #CED4DA' }}
                            mask="99999-9999999-9"
                            id="cnic"
                            name="applicant_cnic"
                            className="h-8 text-center  rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.applicant_cnic}
                        />
                    </div>
                </div>
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">Father Name :</div>
                    <div>
                        <input
                            style={{ border: '1px solid #CED4DA' }}
                            name="father_name"
                            className="h-8   rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.father_name}
                        />
                    </div>
                </div>
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">License No. :</div>
                    <div>
                        <input
                            style={{ border: '1px solid #CED4DA' }}
                            name="licence_no"
                            className="h-8   rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.licence_no}
                        />
                    </div>
                </div>
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">Weapon Type :</div>
                    <div>
                        <input
                            style={{ border: '1px solid #CED4DA' }}
                            name="weapon_type"
                            className="h-8   rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.weapon_type}
                        />
                    </div>
                </div>
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">Caliber :</div>
                    <div>
                        <input
                            style={{ border: '1px solid #CED4DA' }}
                            name="caliber"
                            className="h-8   rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.caliber}
                        />
                    </div>
                </div>
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">Weapon No. :</div>
                    <div>
                        {' '}
                        <input
                            style={{ border: '1px solid #CED4DA' }}
                            name="weapon_no"
                            className="h-8   rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.weapon_no}
                        />
                    </div>
                </div>
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">cartridges :</div>
                    <div>
                        <input
                            style={{ border: '1px solid #CED4DA' }}
                            name="cartridges"
                            className="h-8   rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.cartridges}
                        />
                    </div>
                </div>
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">Status :</div>
                    <div>
                        <input
                            style={{ border: '1px solid #CED4DA' }}
                            name="status"
                            className="h-8   rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                        />
                    </div>
                </div>
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">Issue Date :</div>
                    <div>
                        <input
                            style={{ border: '1px solid #CED4DA' }}
                            name="issue_date"
                            placeholder="mm-dd-yyyy"
                            className="h-8   rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.issue_date}
                        />
                    </div>
                </div>
                <div className="w-full flex items-center border-b border-gray-normal pb-1">
                    <div className="w-40 font-semibold">Expiry Date :</div>
                    <div>
                        <input
                            style={{ border: '1px solid #CED4DA' }}
                            name="expiry_date"
                            placeholder="mm-dd-yyyy"
                            className="h-8   rounded-sm outline-none text-xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.expiry_date}
                        />
                    </div>
                </div>

                <div className="flex space-x-3">
                    <button
                        onClick={() => setEditmodal(!Editmodal)}
                        className="bg-green-normal w-28  h-7 md:h-8 rounded-sm text-white-normal">
                        CANCEL
                    </button>
                    <button
                        // type="submit"
                        className="bg-green-normal w-28  h-7 md:h-8 rounded-sm text-white-normal">
                        SUBMIT
                    </button>
                </div>
            </form>
        </div>
    )

    return (
        <div className="p-5">
            {loading ? (
                <div className="h-screen flex items-center justify-center">
                    Please wait data is fetching ...
                </div>
            ) : (
                <ReactMaterialTable
                    columns={columns}
                    data={formData}
                    searchbar={true}
                    toolbar={true}
                    exportbtn={true}
                    fontSize="12px"
                    pagging={true}
                    id="table"
                    selectedData={''}
                    selectedRow={''}
                    onDeleteRow={onDeleteRow}
                    onEditRow={onEditRow}
                    DeleteBtn={true}
                    EditBtn={true}
                    title={`Licence Verification Data ${formData?.length}`}
                />
            )}

            <ModalComponent
                modalState={Editmodal}
                handleModal={() => setEditmodal(!Editmodal)}
                content={EditModalContent}
            />
            <ModalComponent
                modalState={Deletemodal}
                handleModal={() => setDeletemodal(!Deletemodal)}
                content={DeleteModalContent}
            />
        </div>
    )
}
