import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PagesHandler } from '../components/layout'
// import { PanelHandler } from '../../containers/panels/psycologistpanel/HOC/panelHandler'

// type pageProps = {
//     component: React.ComponentType
//     path?: string
// }

export const AdminRoute = ({ component: RouteComponent }) => {
    const { data } = useSelector((state) => state.UserReducer)
    const navigate = useNavigate()
    useEffect(() => {
        data.Logged && data.Type !== 'Admin' && navigate('/')
    }, [data.Logged, data.Type, navigate])

    return (
        <>
            {data?.Type === 'Admin' && (
                <PagesHandler>
                    <RouteComponent />
                </PagesHandler>
            )}
        </>
    )
}
