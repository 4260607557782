import axios from "axios"
import { BASE_URL } from "../urls"

export const API_HANDLER = async (method, endpoint, data) => {
    var config = {
        method: method,
        url: `${BASE_URL}${endpoint}`,
        headers: {
            'Content-Type': 'application/json',
            authorization: `token ${getLocalStorage('token')}`,
        },
        data: data,
    }
    try {
        const result = await axios(config)
        return {
            data: result.data.data,
            status: true,
        }
    } catch (error) {
        return {
            data: error?.response?.data?.message,
            status: false,
        }
    }
}

export const getLocalStorage = (key) => {
    return localStorage.getItem(key)
}

export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value)
}

export const removeLocalStorage = (key) => {
    return localStorage.removeItem(key)
}