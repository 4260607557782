import { IMG } from '../assets/path'
import InputMask from 'react-input-mask'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { licenseRef } from '../firebase'
import { getDocs, query, where } from 'firebase/firestore'
import { useState } from 'react'
import LoadingBar from 'react-top-loading-bar'
import { API_HANDLER } from '../utils/function'
import { useDispatch } from 'react-redux'
import { END_POINTS } from '../utils/endpoints'

export const Main = () => {
    const [userData, setuserData] = useState()
    const [found, setfound] = useState(0)
    const [loading, setloading] = useState(0)
    const [btnClicked, setbtnClicked] = useState(false)
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            cnic: '',
        },
        validationSchema: Yup.object({
            // cnic: Yup.string().required(
            //     'Please complete this required field.'
            // ),
        }),
        onSubmit: async (values) => {
            setfound(3)

            // console.log(values.cnic)
            const data = await API_HANDLER('POST', END_POINTS.CNIC.GET, {
                applicant_cnic: values?.cnic,
            })
            // const q = query(
            //     licenseRef,
            //     where('applicant_cnic', '==', values.cnic)
            // )
            // console.log(data)
            setbtnClicked(!btnClicked)
            let Loader = 0
            let Interval = setInterval(() => {
                if (loading < 80) {
                    setloading((prev) => prev + 10)
                    Loader = Loader + 10
                }
                if (Loader === 80) {
                    clearInterval(Interval)
                    onCallData(data)
                }
            }, 200)
        },
    })

    const onCallData = async (data) => {
        // const querySnapshot = await getDocs(q)
        // let data = []
        // querySnapshot.forEach((doc) => {
        //     data.push(doc.data())
        // })

        if (data?.data.length > 0) {
            setfound(1)
            setuserData(data?.data)
            setloading(100)
            setTimeout(() => {
                setloading(0)
                setbtnClicked(false)
            }, 1500)
        } else {
            console.log('No such document!')
            setfound(2)
            setloading(100)
            setTimeout(() => {
                setloading(0)
                setbtnClicked(false)
            }, 1500)
        }
    }
    return (
        <div className="w-full flex flex-col items-center min-h-screen text-black-normal ">
            {/* <div
                className={`border-2 border-aqua-normal w-${loading} h-1`}></div> */}
            <LoadingBar
                color="#0091F7"
                progress={loading}
                onLoaderFinished={() => setloading(100)}
            />
            <div className="flex flex-col items-center  w-full md:w-1/2 px-5 md:px-0">
                <div className="space-y-4  flex flex-col items-center justify-center pt-8 pb-2">
                    <img src={IMG.LOGO} alt="" className="w-28 h-28" />
                    <div className="text-base sm:text-xl md:text-2xl text-center font-semibold">
                        ARMS LICENSE VERIFICATION
                    </div>
                </div>
                <div className="bg-white-normal space-y-4 px-0 sm:px-12 py-2 rounded-md shadow-outside">
                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col bg-white-normal rounded-md items-center justify-center space-y-3">
                        <div className="flex flex-col space-y-1">
                            <label
                                className="font-semibold text-center text-xs sm:text-base"
                                htmlFor="cnic">
                                Search By CNIC with dashes
                            </label>
                            <InputMask
                                style={{ border: '1px solid #CED4DA' }}
                                mask="99999-9999999-9"
                                id="cnic"
                                name="cnic"
                                placeholder="12345-1234567-1"
                                className=" py-0.5 px-6 w-full text-base rounded-xs font-semibold placeholder-gray-medium outline-blue-normal duration-1000"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cnic}
                                // errors={formik?.errors?.cnic}
                                // touched={formik?.touched?.cnic}
                            />
                        </div>
                        <button
                            disabled={btnClicked}
                            className="bg-green-normal px-5 py-1.5 font-semibold rounded-sm text-white-normal"
                            type="submit">
                            Submit
                        </button>
                    </form>
                    {found === 1
                        ? userData.map((item, index) => (
                              <table
                                  key={index}
                                  className="w-full  text-xs space-y-2  flex flex-col items-center justify-center">
                                  <tbody className="w-full text-xs space-y-2  flex flex-col items-center justify-center">
                                      {item?.avatar &&
                                          <tr className="w-full flex items-center border-b border-gray-normal pb-1">
                                              <td className="w-28 font-semibold">
                                                  Applicant Image :
                                              </td>
                                              <td>
                                                  <img
                                                      src={
                                                          item?.avatar ||
                                                          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCquktKedv--avxFuVVanKzjajxj4DZaA689FqwBU&s'
                                                      }
                                                      className="w-20 h-20 object-center"
                                                      alt=""
                                                  />
                                              </td>
                                          </tr>
                                      }
                                      <tr className="w-full flex items-center border-b border-gray-normal pb-1">
                                          <td className="w-28 font-semibold">
                                              Applicant Name :
                                          </td>
                                          <td>
                                              {item?.applicant_name
                                                  ? item?.applicant_name
                                                  : 'NA'}
                                          </td>
                                      </tr>
                                      <tr className="w-full flex items-center border-b border-gray-normal pb-1">
                                          <td className="w-28 font-semibold">
                                              Father Name :
                                          </td>
                                          <td>
                                              {item?.father_name
                                                  ? item?.father_name
                                                  : 'NA'}
                                          </td>
                                      </tr>
                                      <tr className="w-full flex items-center border-b border-gray-normal pb-1">
                                          <td className="w-28 font-semibold">
                                              License No. :
                                          </td>
                                          <td>
                                              {item?.licence_no
                                                  ? item?.licence_no
                                                  : 'NA'}
                                          </td>
                                      </tr>
                                      <tr className="w-full flex items-center border-b border-gray-normal pb-1">
                                          <td className="w-28 font-semibold">
                                              Weapon Type :
                                          </td>
                                          <td>
                                              {item?.weapon_type
                                                  ? item?.weapon_type
                                                  : 'NA'}
                                          </td>
                                      </tr>
                                      <tr className="w-full flex items-center border-b border-gray-normal pb-1">
                                          <td className="w-28 font-semibold">
                                              Caliber :
                                          </td>
                                          <td>
                                              {item?.caliber
                                                  ? item?.caliber
                                                  : 'NA'}
                                          </td>
                                      </tr>
                                      <tr className="w-full flex items-center border-b border-gray-normal pb-1">
                                          <td className="w-28 font-semibold">
                                              Weapon No. :
                                          </td>
                                          <td>
                                              {item?.weapon_no
                                                  ? item?.weapon_no
                                                  : 'NA'}
                                          </td>
                                      </tr>
                                      <tr className="w-full flex items-center border-b border-gray-normal pb-1">
                                          <td className="w-28 font-semibold">
                                              Cartridges :
                                          </td>
                                          <td>
                                              {item?.cartridges
                                                  ? item?.cartridges
                                                  : 'NA'}
                                          </td>
                                      </tr>
                                      <tr className="w-full flex items-center border-b border-gray-normal pb-1">
                                          <td className="w-28 font-semibold">
                                              Status :
                                          </td>
                                          <td>
                                              {item?.status
                                                  ? item?.status
                                                  : 'NA'}
                                          </td>
                                      </tr>
                                      <tr className="w-full flex items-center border-b border-gray-normal pb-1">
                                          <td className="w-28 font-semibold">
                                              Issue Date :
                                          </td>
                                          <td>
                                              {item?.issue_date
                                                  ? item?.issue_date
                                                  : 'NA'}
                                          </td>
                                      </tr>
                                      <tr className="w-full flex items-center  pb-1">
                                          <td className="w-28 font-semibold">
                                              Expiry Date :
                                          </td>
                                          <td>
                                              {item?.expiry_date
                                                  ? item?.expiry_date
                                                  : 'NA'}
                                          </td>
                                      </tr>
                                      {userData.length > 1 && (
                                          <tr className="w-full flex items-center  pb-4 border-b border-gray-normal"></tr>
                                      )}
                                  </tbody>
                              </table>
                          ))
                        : found === 2 && (
                              <div className="text-center">No data found.</div>
                          )}
                </div>
            </div>
        </div>
    )
}
