// import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { useDispatch, useSelector } from 'react-redux'
// import { Loading } from '../../components/Loading'
import { IMG } from "../../assets/path";
import * as Yup from "yup";
import { useFormik } from "formik";
import { where, query, getDocs } from "firebase/firestore";
import { adminsRef } from "../../firebase";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { onLogInUser } from "../../redux/actions/user";
import { END_POINTS } from "../../utils/endpoints";
import { API_HANDLER, setLocalStorage } from "../../utils/function";

export const AdminLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [loading, setloading] = useState(false)
  // const { data } = useSelector((state) => state.UserReducer)

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      // cnic: Yup.string().required(
      //     'Please complete this required field.'
      // ),
    }),
    onSubmit: async (values) => {
      const data = await API_HANDLER('POST', END_POINTS.AUTH.LOGIN, {
        Email: values?.email.toLowerCase(),
        Password:values?.password
    })
      // const q = query(
      //   adminsRef,
      //   where("email", "==", values.email),
      //   // where("password", "==", values.password)
      // );
      setLocalStorage('token',data.data.token)
      console.log(data.data)
      // const querySnapshot = await getDocs(q);

      let userData = {};
      // querySnapshot.forEach((doc) => {

      //   userData = doc.data();
      // });

      if (data?.data) {

        dispatch(onLogInUser(data.data));

        navigate("/dashboard");
      } else {
        console.log("No such document!");
      }
    },
  });
  return (
    <div className="py-16 sm:py-12 lg:py-0 px-8 flex flex-col items-center  lg:flex-row h-full min-h-screen">
      <div className=" lg:w-1/2 lg:flex justify-end  px-0 md:px-20">
        <img src={IMG.LOGO} alt="" className="h-28 md:h-48 lg:h-80" />
      </div>
      <div className="w-full lg:w-1/2 flex flex-col px-0 md:px-20  mt-10 lg:mt-0">
        <div className="font-semibold text-2xl md:text-3xl mb-5">Login</div>
        <div className="font-semibold text-xl mb-5">
          Access to our dashboard
        </div>

        <div className="w-full lg:w-10/12">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col space-y-4"
          >
            <input
              name="email"
              type="email"
              autoComplete="off"
              placeholder="Email Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="inputStyle"
            />
            <input
              name="password"
              type="password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              autoComplete="off"
              className="inputStyle"
            />
            <br />

            <button
              className={`w-full h-10 sm:h-12 rounded-md font-semibold bg-green-normal text-white-normal`}
              type="submit"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
