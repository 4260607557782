import { ON_LOGIN_USER, ON_LOGOUT_USER } from '../Types'

const initialState = {
    data: {
        Logged: false,
    },
}

export const UserReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ON_LOGIN_USER: {
            return {
                data: {
                    Logged: true,
                    ...payload,
                },
            }
        }
        case ON_LOGOUT_USER: {
            return {
                data: {
                    Logged: false,
                },
            }
        }

        default:
            return state
    }
}
