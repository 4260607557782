import InputMask from 'react-input-mask'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { IMG } from '../../assets/path'
import { doc, setDoc } from 'firebase/firestore'
import { licenseRef, Firestorage } from '../../firebase'
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage'
import moment from 'moment'
import { useState } from 'react'
import LoadingBar from 'react-top-loading-bar'
import { END_POINTS } from '../../utils/endpoints'
import { API_HANDLER } from '../../utils/function'
export const AddLicence = () => {
    const [imageUpload, setImageUpload] = useState()
    const [progress, setprogress] = useState(0)
    const UploadImage = (e) => {
        e.preventDefault()
        var file = e.target.files[0]
        setImageUpload({
            url: URL.createObjectURL(file),
            file: file,
        })
    }
    const formik = useFormik({
        initialValues: {
            applicant_name: '',
            applicant_cnic: '',
            licence_no: '',
            weapon_type: '',
            caliber: '',
            weapon_no: '',
            cartridges: '',
            status: '',
            issue_date: '',
            expiry_date: '',
        },
        validationSchema: Yup.object({
            applicant_name: Yup.string().required(
                'Please complete this required field.'
            ),
            applicant_cnic: Yup.string().required(
                'Please complete this required field.'
            ),
            licence_no: Yup.string().required(
                'Please complete this required field.'
            ),
            weapon_type: Yup.string().required(
                'Please complete this required field.'
            ),
            caliber: Yup.string().required(
                'Please complete this required field.'
            ),
            weapon_no: Yup.string().required(
                'Please complete this required field.'
            ),
            cartridges: Yup.string().required(
                'Please complete this required field.'
            ),
            status: Yup.string().required(
                'Please complete this required field.'
            ),
            issue_date: Yup.string().required(
                'Please complete this required field.'
            ),
            expiry_date: Yup.string().required(
                'Please complete this required field.'
            ),
        }),
        onSubmit: async(values) => {
            const timestamp = Date.now()

            if (imageUpload !== undefined) {
                var storageref = ref(Firestorage, `pkv/avatars/${timestamp}`)
                var task = uploadBytesResumable(storageref, imageUpload?.file)
                task.on(
                    'state_changed',
                    (snapshot) => {
                        const ping = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                                100
                        )
                        setprogress(ping)
                        //progresss
                    },
                    (error) => {
                        //incomplete
                    },
                    async () => {
                        getDownloadURL(task.snapshot.ref).then(async (url) => {
                            const data = await API_HANDLER('POST', END_POINTS.CNIC.ADD , {                    
                                ...values,
                                avatar: url,
                            })
                            return
                            setDoc(doc(licenseRef, timestamp.toString()), {
                                ...values,
                                issue_date: values.issue_date,
                                expiry_date: values.expiry_date,
                                avatar: url,
                                id: timestamp.toString(),
                            })
                            // setloading(false)
                        })
                    }
                )
            } else {
                const data = await API_HANDLER('POST', END_POINTS.CNIC.ADD , {                    
                    ...values,
                })
                return 
                setDoc(doc(licenseRef, timestamp.toString()), {
                    ...values,
                    issue_date: values.issue_date,
                    expiry_date: values.expiry_date,
                    id: timestamp.toString(),
                })
            }
        },
    })
    return (
        <div className="w-full flex items-center justify-center">
            <LoadingBar
                color="#0091F7"
                progress={progress}
                onLoaderFinished={() => setprogress(0)}
            />
            {progress !== 0 && progress}
            <div className="flex flex-col  items-center justify-center  w-full lg:w-8/12 px-10 md:px-0">
                <div className="space-y-4  flex flex-col items-center justify-center py-10">
                    {/* <img src={IMG.LOGO} alt="" className="w-40 h-28" /> */}
                    <div className="text-2xl font-semibold">
                        ADD ARMS LICENSE VERIFICATION
                    </div>
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                    className="w-full md:w-1/2 text-xs space-y-2  flex flex-col items-center justify-center">
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">
                            Applicant Image :
                        </div>
                        <div className="flex w-full justify-center items-center ">
                            <label
                                htmlFor="imageUpload"
                                className="rounded-md cursor-pointer w-20 h-20">
                                <img
                                    src={
                                        imageUpload
                                            ? imageUpload?.url
                                            : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCquktKedv--avxFuVVanKzjajxj4DZaA689FqwBU&s'
                                    }
                                    alt={
                                        imageUpload?.url !== ''
                                            ? 'Image Uploaded'
                                            : 'Upload Image'
                                    }
                                    className="self-center"
                                />
                            </label>
                            <input
                                type="file"
                                className="hidden"
                                name="photo"
                                onChange={(e) => {
                                    UploadImage(e)
                                }}
                                accept="image/*"
                                id="imageUpload"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">
                            Applicant Name :
                        </div>
                        <div>
                            {' '}
                            <input
                                style={{ border: '1px solid #CED4DA' }}
                                name="applicant_name"
                                className="h-8   text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.applicant_name}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40  font-semibold">
                            Applicant Cnic :
                        </div>
                        <div>
                            <InputMask
                                style={{ border: '1px solid #CED4DA' }}
                                mask="99999-9999999-9"
                                id="cnic"
                                name="applicant_cnic"
                                className="h-8 text-center  text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.applicant_cnic}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">Father Name :</div>
                        <div>
                            <input
                                style={{ border: '1px solid #CED4DA' }}
                                name="father_name"
                                className="h-8   text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.father_name}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">License No. :</div>
                        <div>
                            <input
                                style={{ border: '1px solid #CED4DA' }}
                                name="licence_no"
                                className="h-8   text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.licence_no}
                            />
                        </div>
                    </div>

                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">Weapon Type :</div>
                        <div>
                            <input
                                style={{ border: '1px solid #CED4DA' }}
                                name="weapon_type"
                                className="h-8   text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.weapon_type}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">Caliber :</div>
                        <div>
                            <input
                                style={{ border: '1px solid #CED4DA' }}
                                name="caliber"
                                className="h-8   text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.caliber}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">Weapon No. :</div>
                        <div>
                            {' '}
                            <input
                                style={{ border: '1px solid #CED4DA' }}
                                name="weapon_no"
                                className="h-8   text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.weapon_no}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">cartridges :</div>
                        <div>
                            <input
                                style={{ border: '1px solid #CED4DA' }}
                                name="cartridges"
                                className="h-8   text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cartridges}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">Status :</div>
                        <div>
                            <input
                                style={{ border: '1px solid #CED4DA' }}
                                name="status"
                                className="h-8   text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.status}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">Issue Date :</div>
                        <div>
                            <input
                                style={{ border: '1px solid #CED4DA' }}
                                name="issue_date"
                                // type={'date'}
                                placeholder="mm-dd-yyyy"
                                className="h-8   text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.issue_date}
                            />
                        </div>
                    </div>
                    <div className="w-full flex items-center border-b border-gray-normal pb-1">
                        <div className="w-40 font-semibold">Expiry Date :</div>
                        <div>
                            <input
                                style={{ border: '1px solid #CED4DA' }}
                                name="expiry_date"
                                // type={'date'}
                                placeholder="mm-dd-yyyy"
                                className="h-8   text-base rounded-sm outline-none"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.expiry_date}
                            />
                        </div>
                    </div>
                    <button
                        className="bg-green-normal px-5 py-2 font-semibold rounded-sm text-white-normal"
                        type="submit">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}
