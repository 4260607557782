// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
    getFirestore,
    collection,
    // onSnapshot,
    // addDoc,
    // deleteDoc,
    // doc,
    // query,
    // where,
    // orderBy,
    // serverTimestamp,
    // getDoc,
    // updateDoc,
} from 'firebase/firestore'
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
}

// Initialize Firebase
const  app = initializeApp(firebaseConfig)

export const firestore = getFirestore()

export const auth = getAuth();
export const Firestorage = getStorage(app)
export const licenseRef = collection(firestore, 'License')
export const adminsRef = collection(firestore, 'Admins')
