import {Modal, makeStyles} from '@material-ui/core'
import {FC} from 'react'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.paper',
        outline: 'none',
        border: 'none',
        '&:focus': {
            outline: 'none',
        },
        overflowY: 'scroll',
        // [theme.breakpoints.up('md')]: {
        //   fontSize: '24px',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     overflowY: 'scroll',
        // },
    },
}))

type ModalProps = {
    modalState: boolean
    content: any
    handleModal: any
}

export const ModalComponent: FC<ModalProps> = ({
    modalState,
    content,
    handleModal,
}) => {
    const classes = useStyles()
    return (
        <Modal
            open={modalState}
            onClose={handleModal}
            className={classes.modal}>
            {content}
        </Modal>
    )
}
